import { useContext, useEffect, useMemo } from 'react';

import { Input, Radio, Select, Space } from 'antd';
const { Search } = Input;

import HighlightedTable from '@Components/Tables/HighlightedTable';

import useNotification from '@/hooks/notifications';
import useFetchData from '@/hooks/useFetchData';

import { TopicContext } from '@/context/topicContext/topicContext';

const types = [
	{ label: 'VIDEOS', value: 'EPV_EDITED_VIDEOS' },
	{ label: 'PHOTOSETS', value: 'EPV_PHOTOSET' },
];

const searchTypes = [
	{ label: 'Search by reference ID', value: 'epId' },
	{ label: 'Search by keywords', value: 'search' },
];

export default function HighlightedMediasTable({selected = false}) {
	const {openNotification} = useNotification();
	const { topicId } = useContext(TopicContext);

	const params = useMemo(() => {
		return {
				mediaType: 'EPV_EDITED_VIDEOS',
				sort: 'modified:desc',
				url: `topics/${topicId}/medias/highlighted`,
				selectedMedia: selected,
		};
	}, []);
	const {
		setParam,
		setParams,
		searchParams,
		data,
		loading,
		error,
		getData,
		toggleSelectNew,
		deleteParam
	} = useFetchData({params: params, usePaginaton: false});

	useEffect(() => {
		setParams({sort: 'mediaDate:desc'});
	}, []);

	useEffect(() => {
		deleteParam('page', false);
		setParams({selectedMedia: selected});
	}, [selected]);

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);

	function getMediaType() {
		return searchParams.get("mediaType") || 'EPV_EDITED_VIDEOS';
	}

	const handleType = ({ target: { value } }) => {
		deleteParam('page', false);
		setParams({mediaType: value});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<Radio.Group
				options={types}
				onChange={handleType}
				value={getMediaType()}
				optionType="button"
				size="large"
			/>
			{!selected && <Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>}
			<>
				<HighlightedTable
					data={data}
					loading={loading}
					sort={searchParams.get("sort") || 'mediaDate:desc'}
					setSort={setParam('sort')}
					getListData={getData}
					type={getMediaType()}
					name="medias"
					select={async (id) => await toggleSelectNew({select: true, url: `/topics/${topicId}/medias/${id}/highlighted`})}
					unselect={async (id) => await toggleSelectNew({select: false, url: `/topics/${topicId}/medias/${id}/highlighted`})}
				/>
			</>
		</Space>
	);
}